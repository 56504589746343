<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="反馈类型" prop="type">
                <a-select placeholder="请选择反馈类型" v-model="queryParam.type" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.feedback" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否已处理" prop="handleFlag">
                <a-select placeholder="请选择是否已处理" v-model="queryParam.handleFlag" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.is_or" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:feedback:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:feedback:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:feedback:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:feedback:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :typeOptions="dict.type.feedback"
        :handleFlagOptions="dict.type.is_or"
        @ok="getList"
      />
      <handle-form
        ref="handleForm"
        :typeOptions="dict.type.feedback"
        :handleFlagOptions="dict.type.is_or"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="type" slot-scope="text, record">
          <dict-tag :options="dict.type['feedback']" :value="record.type"/>
        </span>
        <span slot="handleTime" slot-scope="text, record">
          {{ parseTime(record.handleTime) }}
        </span>
        <span slot="handleFlag" slot-scope="text, record">
          <dict-tag :options="dict.type['is_or']" :value="record.handleFlag"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span v-if="record.image" slot="image" slot-scope="text, record" @click="$refs.handleForm.handleDetail(record, undefined)">
          <span v-for="(item,index) in text.split(',')">
                <img style="width: 48px;height: 48px;margin-right: 5px;margin-bottom: 5px;" :src="item" preview="图片"/>
          </span>
        </span>
<!--        <span slot="image" slot-scope="text, record">-->
<!--          <img v-if="record.image" :src="record.image" alt="图片" style="height:48px;max-width:48px"/>-->
<!--        </span>-->
        <span slot="operation" slot-scope="text, record">
<!--          <a @click="$refs.handleForm.handleDetail(record, undefined)" >-->
<!--            <a-icon type="edit" />详情-->
<!--          </a>-->
          <a-divider type="vertical" />
          <a @click="handleDelete(record)">
            <a-icon type="delete" />删除
          </a>
          <a-divider type=" vertical" v-if="record.handleFlag==='0'" />
          <a @click="$refs.handleForm.handleUpdate(record, undefined)" v-if="record.handleFlag==='0'">
            <a-icon type="edit" />处理
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageFeedback,listFeedback, delFeedback } from '@/api/biz/feedback'
import CreateForm from './modules/CreateForm'
import HandleForm from './modules/HandleForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Feedback',
  components: {
    CreateForm,HandleForm
  },
  mixins: [tableMixin],
  dicts: ['feedback', 'is_or'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        type: null,
        content: null,
        handleTime: null,
        handleFlag: null,
        handleContent: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '主键id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '反馈类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '反馈内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户上传图片',
          dataIndex: 'image',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "image"}
        },
        {
          title: '处理时间',
          dataIndex: 'handleTime',
          scopedSlots: { customRender: 'handleTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '是否已处理',
        //   dataIndex: 'handleFlag',
        //   scopedSlots: { customRender: 'handleFlag' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '处理内容',
          dataIndex: 'handleContent',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询意见反馈列表 */
    getList () {
      this.loading = true
     pageFeedback(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        type: undefined,
        content: undefined,
        handleTime: undefined,
        handleFlag: undefined,
        handleContent: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delFeedback(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/feedback/export', {
            ...that.queryParam
          }, `意见反馈_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
